import Command from "@ckeditor/ckeditor5-core/src/command";
import getUrl from "../../getUrl";

export default class InsertRelatedArticleCommand extends Command {
  execute(fullUrl) {
    const slug = fullUrl.split('/').pop();
    
    fetch(`${getUrl()}/blog/${slug}`)
      .then(response => response.json())
      .then(data => {
        const { title, heroImage } = data;
        const uniqueId = Math.random().toString(36).substr(2, 9);

        this.editor.model.change(writer => {
          const relatedArticleBanner = writer.createElement("relatedArticle");
          const relatedArticleContent = writer.createElement("relatedArticleContent");
          const articleImage = writer.createElement("articleImage");
          const articleTextContent = writer.createElement("articleTextContent");
          const articleLabel = writer.createElement("articleLabel");
          const articleTitleElement = writer.createElement("articleTitle");
          const relatedArticleHideContent = writer.createElement("relatedArticleHideContent");

          // Set image as a regular style attribute (will be replaced by frontend)
          writer.setAttribute(
            "style", 
            `background-image: url(${heroImage.photo}); background-size: cover; background-position: center;`,
            articleImage
          );
          
          writer.appendText("Related article", articleLabel);
          writer.appendText(title, articleTitleElement);
          
          // Store both URLs and ID in the hidden content, separated by special characters
          // Format: uniqueId|||linkUrl|||imageUrl
          writer.appendText(`${uniqueId}|||${fullUrl}|||${heroImage.photo}`, relatedArticleHideContent);

          writer.append(articleImage, relatedArticleContent);
          writer.append(articleLabel, articleTextContent);
          writer.append(articleTitleElement, articleTextContent);
          writer.append(articleTextContent, relatedArticleContent);
          writer.append(relatedArticleHideContent, relatedArticleContent);
          writer.append(relatedArticleContent, relatedArticleBanner);

          this.editor.model.insertContent(relatedArticleBanner);
        });
      })
      .catch(error => console.error("Error fetching related article data:", error));
  }

  refresh() {
    const model = this.editor.model;
    const selection = model.document.selection;
    const allowedIn = model.schema.findAllowedParent(
      selection.getFirstPosition(),
      "relatedArticle"
    );
    this.isEnabled = allowedIn !== null;
  }
} 