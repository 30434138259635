import Command from "@ckeditor/ckeditor5-core/src/command";

export default class InsertGetInTouchBannerCommand extends Command {
  execute(url = '/contact-us') {
    this.editor.model.change((writer) => {
      const getInTouchBanner = writer.createElement("getInTouchBanner");
      const getInTouchBannerContent = writer.createElement("getInTouchBannerContent");
      const getInTouchBannerTitle = writer.createElement("getInTouchBannerTitle");
      const getInTouchBannerDescription = writer.createElement("getInTouchBannerDescription");
      const getInTouchBannerLink = writer.createElement("getInTouchBannerLink");
      const getInTouchBannerHideContent = writer.createElement("getInTouchBannerHideContent");

      writer.appendText("Enter your heading", getInTouchBannerTitle);
      writer.appendText("Enter your description", getInTouchBannerDescription);
      writer.appendText("Enter link text", getInTouchBannerLink);
      
      // Store the URL in hidden content
      writer.appendText(url, getInTouchBannerHideContent);
      
      writer.setAttribute('href', '/contact-us', getInTouchBannerLink);
      writer.setAttribute('target', '_blank', getInTouchBannerLink);

      writer.append(getInTouchBannerContent, getInTouchBanner);
      writer.append(getInTouchBannerTitle, getInTouchBannerContent);
      writer.append(getInTouchBannerDescription, getInTouchBannerContent);
      writer.append(getInTouchBannerHideContent, getInTouchBannerContent);
      writer.append(getInTouchBannerLink, getInTouchBanner);

      this.editor.model.insertContent(getInTouchBanner);
    });
  }

  refresh() {
    const model = this.editor.model;
    const selection = model.document.selection;
    const allowedIn = model.schema.findAllowedParent(
      selection.getFirstPosition(),
      "getInTouchBanner"
    );
    this.isEnabled = allowedIn !== null;
  }
} 

// import Command from "@ckeditor/ckeditor5-core/src/command";

// export default class InsertGetInTouchBannerCommand extends Command {
//   execute(url = '/contact-us') {  // Default URL if none provided
//     console.log('url', url)
//     this.editor.model.change((writer) => {
//       const getInTouchBanner = writer.createElement("getInTouchBanner");
//       const getInTouchBannerContent = writer.createElement("getInTouchBannerContent");
//       const getInTouchBannerTitle = writer.createElement("getInTouchBannerTitle");
//       const getInTouchBannerDescription = writer.createElement("getInTouchBannerDescription");
//       const getInTouchBannerLink = writer.createElement("getInTouchBannerLink");
//       const getInTouchBannerHideContent = writer.createElement("getInTouchBannerHideContent");

//       // Set the href attribute
//       writer.setAttribute("href", url, getInTouchBannerLink);
//       writer.setAttribute("target", "_blank", getInTouchBannerLink);
//       writer.appendText(url, getInTouchBannerHideContent);

//       writer.appendText("Get in touch", getInTouchBannerTitle);
//       writer.appendText(
//         "Have a project in mind? Let's talk about it.",
//         getInTouchBannerDescription
//       );
//       writer.appendText("Contact us", getInTouchBannerLink);

//       writer.append(getInTouchBannerTitle, getInTouchBannerContent);
//       writer.append(getInTouchBannerDescription, getInTouchBannerContent);
//       writer.append(getInTouchBannerContent, getInTouchBanner);
//       writer.append(getInTouchBannerHideContent, getInTouchBanner);
//       writer.append(getInTouchBannerLink, getInTouchBanner);

//       this.editor.model.insertContent(getInTouchBanner);
//     });
//   }

//   refresh() {
//     const model = this.editor.model;
//     const selection = model.document.selection;
//     const allowedIn = model.schema.findAllowedParent(
//       selection.getFirstPosition(),
//       "getInTouchBanner"
//     );

//     this.isEnabled = allowedIn !== null;
//   }
// } 