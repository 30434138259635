import Command from "@ckeditor/ckeditor5-core/src/command";

export default class InsertBannerCommand extends Command {
  execute() {
    this.editor.model.change((writer) => {
      const banner = writer.createElement("banner");
      const bannerContent = writer.createElement("bannerContent");
      const bannerTitle = writer.createElement("bannerTitle");
      const bannerDescription = writer.createElement("bannerDescription");
      const bannerButton = writer.createElement("bannerButton");

      // Set default content
      writer.appendText("Download our app", bannerTitle);
      writer.appendText("Get the full experience on your phone", bannerDescription);
      writer.appendText("Download", bannerButton);

      // Structure: content (title, description) on left, button on right
      writer.append(bannerTitle, bannerContent);
      writer.append(bannerDescription, bannerContent);
      writer.append(bannerContent, banner);  // Content first
      writer.append(bannerButton, banner);   // Button second

      this.editor.model.insertContent(banner);
    });
  }

  refresh() {
    const model = this.editor.model;
    const selection = model.document.selection;
    const allowedIn = model.schema.findAllowedParent(
      selection.getFirstPosition(),
      "banner"
    );

    this.isEnabled = allowedIn !== null;
  }
}
