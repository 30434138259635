import Plugin from "@ckeditor/ckeditor5-core/src/plugin";
import ButtonView from "@ckeditor/ckeditor5-ui/src/button/buttonview";

export default class GetInTouchBannerUI extends Plugin {
  init() {
    const editor = this.editor;
    const t = editor.t;

    editor.ui.componentFactory.add("getInTouchBanner", (locale) => {
      const command = editor.commands.get("insertGetInTouchBanner");
      const buttonView = new ButtonView(locale);

      buttonView.set({
        label: "CTA",
        withText: true,
        tooltip: true,
      });

      buttonView.bind('isOn').to(command, 'value');
      buttonView.bind('isEnabled').to(command, 'isEnabled');

      this.listenTo(buttonView, "execute", () => {
        const url = prompt("Enter URL for the Get in Touch banner (leave empty for default /contact-us):");
        editor.execute("insertGetInTouchBanner", url || '/contact-us');
      });

      return buttonView;
    });
  }
} 