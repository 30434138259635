import React from "react";
import classnames from "classnames";
import { REDUX_FORM_NAME } from "react-admin";
import { addField } from "ra-core";
import { withStyles } from "@material-ui/core";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";

import MyCustomUploadAdapterPlugin from "../ImageUploadProvider";

import Autoformat from "@ckeditor/ckeditor5-autoformat/src/autoformat.js";
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold.js";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic.js";
import BlockQuote from "@ckeditor/ckeditor5-block-quote/src/blockquote.js";
import Heading from "@ckeditor/ckeditor5-heading/src/heading.js";
import Image from "@ckeditor/ckeditor5-image/src/image.js";
import ImageCaption from "@ckeditor/ckeditor5-image/src/imagecaption.js";
import ImageStyle from "@ckeditor/ckeditor5-image/src/imagestyle.js";
import ImageToolbar from "@ckeditor/ckeditor5-image/src/imagetoolbar.js";
import ImageUpload from "@ckeditor/ckeditor5-image/src/imageupload.js";
import Indent from "@ckeditor/ckeditor5-indent/src/indent.js";
import Link from "@ckeditor/ckeditor5-link/src/link.js";
import List from "@ckeditor/ckeditor5-list/src/list.js";
import MediaEmbed from "@ckeditor/ckeditor5-media-embed/src/mediaembed.js";
import Table from "@ckeditor/ckeditor5-table/src/table.js";
import TableToolbar from "@ckeditor/ckeditor5-table/src/tabletoolbar.js";
import Alignment from "@ckeditor/ckeditor5-alignment/src/alignment.js";
import Autosave from "@ckeditor/ckeditor5-autosave/src/autosave.js";
import Code from "@ckeditor/ckeditor5-basic-styles/src/code.js";
import CodeBlock from "@ckeditor/ckeditor5-code-block/src/codeblock.js";
import FontColor from "@ckeditor/ckeditor5-font/src/fontcolor.js";
import FontSize from "@ckeditor/ckeditor5-font/src/fontsize.js";
import Highlight from "@ckeditor/ckeditor5-highlight/src/highlight.js";
import HorizontalLine from "@ckeditor/ckeditor5-horizontal-line/src/horizontalline.js";
import ImageResize from "@ckeditor/ckeditor5-image/src/imageresize.js";
import IndentBlock from "@ckeditor/ckeditor5-indent/src/indentblock.js";
import MediaEmbedToolbar from "@ckeditor/ckeditor5-media-embed/src/mediaembedtoolbar.js";
import PasteFromOffice from "@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice";
import RemoveFormat from "@ckeditor/ckeditor5-remove-format/src/removeformat.js";
import SimpleUploadAdapter from "@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter.js";
import Strikethrough from "@ckeditor/ckeditor5-basic-styles/src/strikethrough.js";
import TableCellProperties from "@ckeditor/ckeditor5-table/src/tablecellproperties";
import TableProperties from "@ckeditor/ckeditor5-table/src/tableproperties";
import TodoList from "@ckeditor/ckeditor5-list/src/todolist";
import Underline from "@ckeditor/ckeditor5-basic-styles/src/underline.js";
import WordCount from "@ckeditor/ckeditor5-word-count/src/wordcount.js";
import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials.js";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph.js";
import Banner from "./bannerPlugin/banner";
import GetInTouchBanner from "./getInTouchBanner/getintouchbanner";
import RelatedArticleBanner from "./relatedArticleBanner/relatedarticlebanner";
import "./banner.css";
import './getintouchbanner.css'
import './relatedarticlebanner.css'

const editorConfiguration = {
  plugins: [
    Autoformat,
    Bold,
    Italic,
    BlockQuote,
    Heading,
    Image,
    ImageCaption,
    ImageStyle,
    ImageToolbar,
    ImageUpload,
    Indent,
    Link,
    List,
    MediaEmbed,
    Table,
    TableToolbar,
    Alignment,
    Autosave,
    Code,
    CodeBlock,
    FontColor,
    FontSize,
    Highlight,
    HorizontalLine,
    ImageResize,
    IndentBlock,
    MediaEmbedToolbar,
    PasteFromOffice,
    RemoveFormat,
    SimpleUploadAdapter,
    Strikethrough,
    TableCellProperties,
    TableProperties,
    TodoList,
    Underline,
    WordCount,
    Essentials,
    Paragraph,
    Banner,
    GetInTouchBanner,
    RelatedArticleBanner,
  ],
  extraPlugins: [MyCustomUploadAdapterPlugin],
  table: {
    contentToolbar: [
      "tableProperties",
      "tableCellProperties",
      "tableColumn",
      "tableRow",
      "mergeTableCells",
    ],
  },
  codeBlock: {
    languages: [
      { language: "plaintext", label: "Plain text" }, // The default language.
      { language: "css", label: "CSS" },
      { language: "javascript", label: "JavaScript" },
      { language: "html", label: "HTML" },
      { language: "svg", label: "SVG" },
      { language: "xml", label: "XML" },
      { language: "bash", label: "Bash" },
      { language: "c", label: "C" },
      { language: "cpp", label: "C++" },
      { language: "csharp", label: "C#" },
      { language: "git", label: "Git" },
      { language: "go", label: "Go" },
      { language: "sgraphql", label: "GraphQL" },
      { language: "http", label: "HTTP" },
      { language: "java", label: "Java" },
      { language: "json", label: "JSON" },
      { language: "kotlin", label: "Kotlin" },
      { language: "nginx", label: "Nginx" },
      { language: "objectivec", label: "Objective C" },
      { language: "php", label: "PHP" },
      { language: "py", label: "Python" },
      { language: "jsx", label: "React JSX" },
      { language: "tsx", label: "React TSX" },
      { language: "sass", label: "Sass" },
      { language: "scss", label: "Scss" },
      { language: "sql", label: "SQL" },
      { language: "swift", label: "Swift" },
      { language: "ts", label: "TypeScript" },
      { language: "yaml", label: "Yaml" },
    ],
  },
  basicEntities: false,
  entities_greek: false,
  entities_latin: false,
  entities_additional: "",
  link: {
    addTargetToExternalLinks: true,
  },
};

const styles = (theme) => ({
  wrapper: {
    marginTop: 38,
    marginBottom: 8,
    "& > span": {
      color: "#000",
    },
    "& .ck-editor": {
      border: "1px solid rgba(0, 0, 0, 0.42)",
      borderRadius: 4,
      marginTop: 6,
      outline: "none",
    },
    "& .ck-content": {
      borderColor: "#c4c4c4 !important",
    },
    "& .ck.ck-toolbar.ck-toolbar_grouping>.ck-toolbar__items": {
      flexWrap: "wrap",
    },
    "& > p": {
      margin: 0,
      fontSize: "0.75rem",
      marginTop: 8,
      minHeight: "1em",
      lineHeight: "1em",
      color: theme.palette.error.main,
    },
    "&:focus-within": {
      "& .ck-editor": {
        border: `2px solid ${theme.palette.primary.dark}`,
      },
    },
    "&:hover": {
      "& .ck-editor": {
        border: `2px solid rgba(0, 0, 0, 0.87)`,
      },
    },
  },
  error: {
    "& .ck-editor": {
      border: `2px solid ${theme.palette.error.main}`,
    },
    "&:hover": {
      "& .ck-editor": {
        border: `2px solid ${theme.palette.error.main}`,
      },
    },
  },
});

class CustomEditor extends React.Component {
  constructor(props) {
    super(props);

    this.toolbar = [
      "heading",
      "bold",
      "italic",
      "bulletedList",
      "numberedList",
      "blockQuote",
      "indent",
      "link",
      "mediaEmbed",
      "insertTable",
      "fontColor",
      "fontSize",
      "highlight",
      "alignment",
      "horizontalLine",
      "todoList",
      "strikethrough",
      "underline",
      "code",
      "codeBlock",
      "removeFormat",
      "undo",
      "redo",
      "banner",
      "getInTouchBanner",
      "insertRelatedArticle"
    ];

    if (!props.disableImageUpload) {
      this.toolbar.splice(6, 0, 'imageUpload');
    }
  }


  render() {
    const {
      classes,
      record,
      meta: { touched, error },
      label,
      change,
      forFAQ = {},
    } = this.props;
    const { source, order, kind } = forFAQ;

    const data =
      record && record.text
        ? record.text
        : typeof record === "string"
        ? record
        : "";

    const reduxField = source ? `${source}[${order}].${kind}` : "text";
    const handleChange = (event, editor) => {
      const data = editor.getData();
      change(REDUX_FORM_NAME, reduxField, data);
    };

    const style = source ? { marginLeft: 18 } : null;

    return (
      <div
        className={classnames(
          classes.wrapper,
          !!(touched && error) ? classes.error : null,
          forFAQ.source ? "ckQAMargin" : null
        )}
        style={style}
      >
        <span>{label}</span>
        <CKEditor
          editor={ClassicEditor}
          data={data}
          config={{
            ...editorConfiguration,
            toolbar: this.toolbar,
          }}
          onChange={handleChange}
        />
        {!!(touched && error) && <p>{error}</p>}
      </div>
    );
  }
}

export const Editor = React.memo(withStyles(styles)(addField(CustomEditor)));
