import ButtonView from "@ckeditor/ckeditor5-ui/src/button/buttonview";
import Plugin from "@ckeditor/ckeditor5-core/src/plugin";

export default class RelatedArticleUI extends Plugin {
  init() {
    const editor = this.editor;
    const t = editor.t;

    editor.ui.componentFactory.add("insertRelatedArticle", (locale) => {
      const command = editor.commands.get("insertRelatedArticle");
      const buttonView = new ButtonView(locale);

      buttonView.set({
        label: t("Related Article"),
        withText: true,
        tooltip: true,
      });

      buttonView.bind("isEnabled").to(command, "isEnabled");

      this.listenTo(buttonView, "execute", () => {
        const url = prompt("Enter the full URL of article to fetch related article data:");
        if (url) {
          editor.execute("insertRelatedArticle", url);
        }
      });

      return buttonView;
    });
  }
} 