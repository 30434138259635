import Plugin from "@ckeditor/ckeditor5-core/src/plugin";
import { toWidget, toWidgetEditable } from "@ckeditor/ckeditor5-widget/src/utils";
import Widget from "@ckeditor/ckeditor5-widget/src/widget";
import InsertRelatedArticleCommand from "./insertRelatedArticleCommand";

export default class RelatedArticleEditing extends Plugin {
  static get requires() {
    return [Widget];
  }

  init() {
    this._defineSchema();
    this._defineConverters();

    this.editor.commands.add(
      "insertRelatedArticle",
      new InsertRelatedArticleCommand(this.editor)
    );
  }

  _defineSchema() {
    const schema = this.editor.model.schema;

    schema.register("relatedArticle", {
      isObject: true,
      allowWhere: "$block",
      allowAttributes: ["class", "href", "target"]
    });

    schema.register("relatedArticleContent", {
      isLimit: true,
      allowIn: "relatedArticle",
    });

    schema.register("articleImage", {
      isLimit: true,
      allowIn: "relatedArticleContent",
      allowContentOf: "$block",
      allowAttributes: ["style"]
    });

    schema.register("articleTextContent", {
      isLimit: true,
      allowIn: "relatedArticleContent",
      allowContentOf: "$block",
    });

    schema.register("articleLabel", {
      isLimit: true,
      allowIn: "articleTextContent",
      allowContentOf: "$block",
    });

    schema.register("articleTitle", {
      isLimit: true,
      allowIn: "articleTextContent",
      allowContentOf: "$block",
    });

    schema.register("relatedArticleHideContent", {
      isLimit: true,
      allowIn: "relatedArticleContent",
      allowContentOf: "$block",
      allowAttributes: ["data-banner-id"]
    });
  }

  _defineConverters() {
    const conversion = this.editor.conversion;

    // Related Article converters
    conversion.for("upcast").elementToElement({
      model: "relatedArticle",
      view: {
        name: "a",
        classes: "related-article",
        attributes: {
          href: true,
          target: true
        }
      }
    });

    conversion.for("upcast").attributeToAttribute({
      view: {
        name: "a",
        key: "href"
      },
      model: {
        key: "href",
        value: viewElement => viewElement.getAttribute("href")
      }
    });

    conversion.for("dataDowncast").elementToElement({
      model: "relatedArticle",
      view: (modelElement, viewWriter) => {
        return viewWriter.createContainerElement("a", {
          class: "related-article",
          href: modelElement.getAttribute("href") || "#",
          target: "_blank"
        });
      }
    });

    conversion.for("editingDowncast").elementToElement({
      model: "relatedArticle",
      view: (modelElement, viewWriter) => {
        const section = viewWriter.createContainerElement("a", {
          class: "related-article",
          href: modelElement.getAttribute("href") || "#",
          target: "_blank"
        });
        return toWidget(section, viewWriter, { label: "related article widget" });
      }
    });

    // Content wrapper converters
    conversion.for("upcast").elementToElement({
      model: "relatedArticleContent",
      view: {
        name: "div",
        classes: "related-article-content",
      },
    });
    conversion.for("dataDowncast").elementToElement({
      model: "relatedArticleContent",
      view: {
        name: "div",
        classes: "related-article-content",
      },
    });
    conversion.for("editingDowncast").elementToElement({
      model: "relatedArticleContent",
      view: (modelElement, viewWriter) => {
        const div = viewWriter.createEditableElement("div", {
          class: "related-article-content",
        });
        return toWidgetEditable(div, viewWriter);
      },
    });

    // Image converters
    conversion.for("upcast").elementToElement({
      model: "articleImage",
      view: {
        name: "div",
        classes: "article-image"
      }
    });
    conversion.for("dataDowncast").elementToElement({
      model: "articleImage",
      view: (modelElement, viewWriter) => {
        const style = modelElement.getAttribute("style");
        return viewWriter.createContainerElement("div", {
          class: "article-image",
          style
        });
      }
    });
    conversion.for("editingDowncast").elementToElement({
      model: "articleImage",
      view: (modelElement, viewWriter) => {
        const style = modelElement.getAttribute("style");
        return viewWriter.createContainerElement("div", {
          class: "article-image",
          style
        });
      }
    });

    // Title converters
    conversion.for("upcast").elementToElement({
      model: "articleTitle",
      view: {
        name: "h2",
        classes: "article-title",
      },
    });
    conversion.for("dataDowncast").elementToElement({
      model: "articleTitle",
      view: {
        name: "h2",
        classes: "article-title",
      },
    });
    conversion.for("editingDowncast").elementToElement({
      model: "articleTitle",
      view: (modelElement, viewWriter) => {
        const h2 = viewWriter.createEditableElement("h2", {
          class: "article-title",
        });
        return toWidgetEditable(h2, viewWriter);
      },
    });

    // Add label converters
    conversion.for("upcast").elementToElement({
      model: "articleLabel",
      view: {
        name: "p",
        classes: "article-label",
      },
    });
    
    conversion.for("dataDowncast").elementToElement({
      model: "articleLabel",
      view: {
        name: "p",
        classes: "article-label",
      },
    });
    
    conversion.for("editingDowncast").elementToElement({
      model: "articleLabel",
      view: (modelElement, viewWriter) => {
        const p = viewWriter.createEditableElement("p", {
          class: "article-label",
        });
        return toWidgetEditable(p, viewWriter);
      },
    });

    // Add converters for the text content wrapper
    conversion.for("upcast").elementToElement({
      model: "articleTextContent",
      view: {
        name: "div",
        classes: "article-text-content",
      },
    });
    
    conversion.for("dataDowncast").elementToElement({
      model: "articleTextContent",
      view: {
        name: "div",
        classes: "article-text-content",
      },
    });
    
    conversion.for("editingDowncast").elementToElement({
      model: "articleTextContent",
      view: (modelElement, viewWriter) => {
        const div = viewWriter.createEditableElement("div", {
          class: "article-text-content",
        });
        return toWidgetEditable(div, viewWriter);
      },
    });

    // Add to converters
    conversion.for("upcast").elementToElement({
      model: "relatedArticleHideContent",
      view: {
        name: "p",
        classes: "related-article-hide-content"
      }
    });

    conversion.for("dataDowncast").elementToElement({
      model: "relatedArticleHideContent",
      view: {
        name: "p",
        classes: "related-article-hide-content"
      }
    });

    conversion.for("editingDowncast").elementToElement({
      model: "relatedArticleHideContent",
      view: (modelElement, viewWriter) => {
        const div = viewWriter.createEditableElement("p", {
          class: "related-article-hide-content",
        });
        return toWidgetEditable(div, viewWriter);
      },
    });
  }
} 